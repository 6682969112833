.footer {
  color: rgba(255, 255, 255, 0.9) !important;
  background-color: rgba(27, 28, 29, 0.95);
  padding-top: 3em;
  padding-bottom: 3em;
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.6rem;
  list-style-type: none;

  // the twitter, github, etc icons at the bottom
  .brand-icon {
    color: white;
    height: 4em;
    width: auto;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }

  h3 {
    color: white;
    font-size: 1.2em;
    font-weight: 400;
  }

  li {
    // remove dots in lists of stuff
    list-style-type: none;
  }
  ul {
    padding-left: 0;
  }

  a {
    // make links lighter blue so readable
    color: #0080ff;
  }
}
