.user-button {
  font-family: unset;
  font-size: unset;
  font-weight: unset;
  line-height: 1em;
  border-radius: 0;
  padding: 0.75em;
  border: 0;
  box-shadow: inset 0 0 0 2px #fff !important;

  .profile-dropdown-menu {
    //height: 2em;
    color: white;
  }
}

.login-button {
  height: 100%;
  cursor: pointer;
}
