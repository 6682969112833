.navbar {
  // define color of instant-quote button here
  $button-color: #2ecc40;
  $button-color: #0080ff;
  $button-color: white;

  background-color: #1b1c1d;
  font-size: 1em !important;
  font-weight: 300;
  margin-bottom: 0;
  align-items: center;
  display: flex;

  /* Style navigation menu links */
  a {
    color: white;
    text-decoration: none;
  }

  .navbar-toggler {
    margin: 0.5em;
  }

  .navbar-brand {
    font-size: 1em;
  }
  .logo-image {
    height: 1em;
    margin-right: 0.5em;
    margin-bottom: 0.15em;
  }
  .instant-button {
    background-color: transparent;
    -webkit-box-shadow: 0 0 0 2px $button-color inset !important;
    box-shadow: inset 0 0 0 2px $button-color !important;
    color: $button-color;
    background-color: transparent;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: none;
    border: none;
    vertical-align: baseline;
    padding: 0.75em 1.5em;
    text-transform: none;
    text-shadow: none;
    white-space: nowrap;

    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition:
      opacity 0.1s ease,
      background-color 0.1s ease,
      color 0.1s ease,
      background 0.1s ease,
      -webkit-box-shadow 0.1s ease;
    transition:
      opacity 0.1s ease,
      background-color 0.1s ease,
      color 0.1s ease,
      background 0.1s ease,
      -webkit-box-shadow 0.1s ease;
    transition:
      opacity 0.1s ease,
      background-color 0.1s ease,
      color 0.1s ease,
      box-shadow 0.1s ease,
      background 0.1s ease;
    transition:
      opacity 0.1s ease,
      background-color 0.1s ease,
      color 0.1s ease,
      box-shadow 0.1s ease,
      background 0.1s ease,
      -webkit-box-shadow 0.1s ease;
  }

  .instant-button:hover {
    background-color: $button-color;
    color: white;
  }

  .nav-text {
    line-height: 2.5em;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }
  .nav-text:hover {
    background-color: #ddd;
    color: black;
  }

  .nav-item {
    margin-left: 0.75em;
    margin-right: 0.75em;
    /* Add a grey background color on mouse-over */
  }

  @media only screen and (max-width: 767px) {
    .nav-item {
      margin: 0.5em;
    }
  }

  .centertxt {
    display: table-cell;
    vertical-align: middle;
  }
}
