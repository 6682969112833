@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-BoldItalic.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-SemiboldItalic.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-Extrabold.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-Extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-Semibold.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-ExtraboldItalic.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-ExtraboldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-Bold.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-Italic.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSansLight-Italic.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSansLight-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans-Light.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src:
    url('open-sans/web/subset-OpenSans.woff2') format('woff2'),
    url('open-sans/web/subset-OpenSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
