/* fonts.scss
 * 
 * Import and apply all `font-family` changes here.
 *
 * Each directory includes an SCSS webfont loadout as
 * well as a static `ttf` directory which can be used
 * in workers to render things natively.
 */

@import 'open-sans';

body {
  font-family: 'Open Sans', sans-serif !important;
}
