.landing {
  // all the mobile rules go here
  @media (max-width: 999px) {
    .new-leftpanel {
      display: none;
    }
    .blurb,
    .upload-block {
      padding: 1.5em;
    }
  }
  @media (min-width: 1000px) {
    .blurb {
      padding: 10em;
    }
    .upload-block {
      padding: 10em;
      padding-top: 25em;
      max-width: 90em;
    }
  }

  .left-panel {
    background-color: #f4f4f4;

    padding-left: 0em;
    padding-right: 0em;

    h1 {
      font-weight: 300;
      font-size: 3em;
      overflow: unset;
    }
    p {
      padding-top: 2em;
      font-weight: 400;
    }
  }

  .carousel {
    padding-bottom: 2.5em;
  }
  .carousel-indicators li {
    background-color: #0080ff;
  }

  .lower-panel {
    background-color: white;
    min-height: 25em;
    padding: 1em;
    p {
      padding-top: 0;
    }
  }

  .right-panel {
    background-color: #393939;
    color: #edeef0;
    background-image: url('../../static/images/turbine.png');
    background-repeat: no-repeat;
    background-size: 175%;
    p {
      margin-bottom: 0;
    }
    h5 {
      font-weight: 200;
      color: #f0f0f0;
    }
    .dropzone {
      outline: none;
      text-align: center;
      border-style: dashed;
      background-color: #0080ff;
      border: none;
      padding: 0;
      margin-top: 2em;
      margin-bottom: 2em;
      font-weight: 600;
      color: #edeef0;
      cursor: pointer;
      p {
        padding: 0.5em;
      }
    }
  }
}
