/**
   * The geosuggest module
   * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
   */
.geosuggest {
  text-align: left;
  border-radius: 0 !important;
}
.geosuggest__input {
  width: 100%;
  border: 1px solid #3d464d44;

  border-radius: 0 !important;
  padding: 0.375rem 0.75rem;
  -webkit-transition:
    border 0.2s,
    box-shadow 0.2s;
  transition:
    border 0.2s,
    box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem #0d6efd40;
  outline: 0;
  background-color: #fff;
  color: #212529;
}
.geosuggest__suggests {
  top: 100%;
  left: 1em;
  right: 1em;
  max-height: 25em;
  padding: 0;
  margin-top: 10px;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition:
    max-height 0.2s,
    border 0.2s;
  transition:
    max-height 0.2s,
    border 0.2s;

  box-shadow: 0 0 0 0.25rem #0d6efd40;
  border: 1px solid #86b7fe;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border: none !important;
  box-shadow: none !important;
  margin-top: 0px !important;
}

/**
   * A geosuggest item
   */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  font-weight: normal;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
