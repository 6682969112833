/** BOOTSTRAP OVERRIDES **/
@use 'sass:math';

@import 'bootstrap/scss/bootstrap';

.btn,
.alert,
.card,
.input-group-text,
.form-control,
.modal-content {
  border-radius: 0;
}

.card {
  padding: 0;
  border-radius: 0;
  .card-body {
    background-color: rgba(0, 0, 0, 0.02);
  }
}

.form-control {
  height: unset;
  width: 100%;
  border: 1px solid #3d464d44;
  border-radius: 0 !important;
  padding: 0.375rem 0.75rem;
  -webkit-transition:
    border 0.2s,
    box-shadow 0.2s;
  transition:
    border 0.2s,
    box-shadow 0.2s;
}

.part-config-group {
  padding-bottom: 1em;

  p {
    font-style: italic;
    margin-top: 0.75em;
    margin-bottom: 0.25em;
  }
  .part-config {
    border: 1px solid #3d464d44;
    .warn {
      background-color: #ffc107;
      border: 0px;
    }
    .ok {
      background-color: transparent;
    }
    .btn {
      text-align: left;
      width: 100%;
    }
  }

  .part-config-post {
    padding: 0.75em;
    border: 1px solid #3d464d44;
  }
}

// make modals show up above their trigger inside embeds
.modal-embedded {
  top: unset;
  .modal-dialog-embedded {
    max-width: 50em;
  }
}

.stripe-cardelement {
  height: unset;
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  border-radius: 0 !important;
  padding: 10.6px 0.75rem;
  -webkit-transition:
    border 0.2s,
    box-shadow 0.2s;
  transition:
    border 0.2s,
    box-shadow 0.2s;
  line-height: 26px;
}
/* ovverride bootstrap container width to match Semantic */
@media (min-width: 1200px) {
  .container-xl {
    max-width: 80% !important;
  }
}

// bootstrap card-decks don't wrap at all
// this is magic to make them wrap
// Bootstrap 4 breakpoints & gutter
.card-deck {
  $grid-breakpoints: (
    xs: 0,
    sm: 36rem,
    md: 48rem,
    lg: 62rem,
    xl: 75rem,
  ) !default;

  $grid-gutter-width: 30px !default;

  // number of cards per line for each breakpoint
  $cards-per-line: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3,
  );

  @each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
      .card {
        flex: 0 0 calc(33.3% - #{$grid-gutter-width});
        margin-top: $grid-gutter-width;
      }
    }
  }
}

.dropdown-item-default {
  background-color: lightblue !important;
}

.dropdown-item-default:hover {
  background-color: lightblue !important;
}
